@function toRem($a) {
    @return ($a/37.5) + rem;
}
.back img{
    height: toRem(210);
}

.login{
    position: relative;
    top:toRem(-40);
    height: toRem(258);
    width: toRem(694/2);
    background-color: #fff;
    border-radius: toRem(8);
    box-sizing: border-box;
    padding:toRem(43) toRem(32);
    margin:0 auto;
    .form-item{
        display: flex;
        padding-bottom: toRem(8);
        border-bottom: toRem(1) solid rgba(51,51,51,0.1);
        &:first-child{
            margin-bottom: toRem(33);
        }
      
        img{
            width: toRem(21);
            height: toRem(21);
        }
        input{
            font-size: toRem(12);
            padding-left: toRem(8);
        }
    }
    .btn{
        margin-top: toRem(50);
        button{
            height: toRem(36);
            padding:0;
            border-radius: toRem(21);
            background-color: #3B6CF5;
            font-size: toRem(14);
        }
    }
}