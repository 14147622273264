@function toRem($a) {
    @return ($a/37.5) + rem;
}

.service-provider {
    padding: 0 toRem(12);
    overflow: hidden;
    margin-top: toRem(13);
    .form-item {
        padding: toRem(15) toRem(12);
        background-color: #fff;
        border-radius: toRem(8);
        margin-bottom: toRem(8);
        &:nth-child(7) {
            .title:after {
                content: "（请上传清晰图片）";
                color: #ff0f0b;
                font-size: toRem(11);
            }
        }
        .title {
            font-size: toRem(15);
            font-weight: bold;
            color: #333;
            margin-bottom: toRem(15);
            &:before {
                content: "*";
                color: #ff0f0b;
                font-size: toRem(11);
            }
        }
        .area{
            display: flex;
            justify-content: space-between;
            align-items: center;
            span{
                font-size: toRem(14);
            }
        }
        .area-type{
            margin-bottom:toRem(15);
            display: flex;
            justify-content: space-between;
            .name{
                font-size: toRem(15);
                font-weight: bold;
                width: toRem(150);
            }
        }
        input{
            font-size: toRem(14);
        }
        .business_license {
            height: toRem(177);
            background-color: #f4f7fc;
            position: relative;
            .add {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                img {
                    width: toRem(48.5);
                    height: toRem(48.5);
                }
                input{
                    position: absolute;
                    top:0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                }
            }
            .business_license_img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
            .close{
                position: absolute;
                z-index: 2;
                right:toRem(-5);
                top:toRem(-5);
            }
        }
    }
    .btn {
        margin-top: toRem(25);
        button {
            padding: 0;
            background-color: #3b6cf5;
            border-radius: toRem(21);
            font-size: toRem(14);
            height: toRem(41);
        }
    }
}
