@function toRem($a) {
    @return ($a/37.5) + rem;
}
.business-list{
    color: #333;
    font-size: toRem(14);
    .header{
        background-color: #fff;
        overflow: hidden;
        position: sticky;
        left: 0;
        top: 0;
        z-index: 3;
        .search{
            padding:0 toRem(14);
            margin: toRem(15) 0;
            display: flex;
            align-items: center;
            .adm-search-bar{
                flex:1;
            }
        }
    }
    .list{
        padding:0 toRem(14);
        padding-bottom: toRem(15);
        a{
            text-decoration: none;
        }
        .item{
            background-color: #fff;
            margin-top: toRem(15);
            border-radius: toRem(5);
            padding:toRem(14);
            color: #333;
            .create-time{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: toRem(10);
            }
            .business-name{
                margin-bottom: toRem(10);
            }
            .btn{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .left{
                   flex:1;
                   font-size: toRem(13);
                   color: red;
                   margin-right: toRem(10);
                }
            }
        }
    }
}
